/**
 * @hidden
 *
 * An object which contains the information about the cities within the timezone.
 */
export const timezones = {
    rules: {},
    titles: {},
    zones: {}
};
